import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom'; // Import Link
import { useAuth } from './AuthContext'; // Import useAuth

const OrderList = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const { tokens } = useAuth(); // Destructure tokens from the context
  const [activeTab, setActiveTab] = useState('pending');

  useEffect(() => {
    if (tokens.access) {
      // Set the authorization header using the access token
      const config = {
        headers: { Authorization: `Bearer ${tokens.access}` }
      };

      // Fetch the orders from the API with the authorization header
      axios.get('/api/orders/', config)
        .then(response => {
          setOrders(response.data);
          setLoading(false);
        })
        .catch(error => {
          console.error("There was an error fetching the orders:", error);
          setLoading(false);
        });
    } else {
      console.log('Token not available');
      // Optionally redirect to login or handle the missing token as needed
    }
  }, [tokens]); // Add tokens to the dependency array
  const getFilteredOrders = () => {
    if (activeTab === 'all') {
      return orders;
    }
    return orders.filter(order => order.status === activeTab);
  };

  const tabStyle = { cursor: 'pointer' };


  return (
    <div>
      <h2 className="mb-4">Order List</h2>

      {/* Tab Bar for Order Status */}
      <ul className="nav nav-tabs mb-4">
        <li className="nav-item">
          <a className={`nav-link ${activeTab === 'all' ? 'active' : ''}`} style={tabStyle} onClick={() => setActiveTab('all')}>All Orders</a>
        </li>
        <li className="nav-item">
          <a className={`nav-link ${activeTab === 'pending' ? 'active' : ''}`} style={tabStyle} onClick={() => setActiveTab('pending')}>Pending</a>
        </li>
        <li className="nav-item">
          <a className={`nav-link ${activeTab === 'ordered' ? 'active' : ''}`} style={tabStyle} onClick={() => setActiveTab('ordered')}>Ordered</a>
        </li>
        <li className="nav-item">
          <a className={`nav-link ${activeTab === 'completed' ? 'active' : ''}`} style={tabStyle} onClick={() => setActiveTab('completed')}>Completed</a>
        </li>
      </ul>

      {loading ? (
        <p>Loading...</p>
      ) : (
        <table className="table table-striped table-hover">
          <thead className="table-dark">
            <tr>
              <th>Order ID</th>
              <th>Status</th>
              <th>Ordered By</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {getFilteredOrders().map(order => (
              <tr key={order.id}>
                <td>{order.id}</td>
                <td>{order.status}</td>
                <td>{order.user.username}</td>
                <td>
                  <Link to={`/orders/${order.id}/edit`} className="btn btn-primary btn-sm">Edit</Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};


export default OrderList;
