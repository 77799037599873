import React, { useState, useEffect } from 'react';  // Import useContext
import axios from 'axios';
import { useAuth } from './AuthContext'; // Import useAuth
import { useParams } from 'react-router-dom';
import { Form, Button, Card, InputGroup, FormControl } from 'react-bootstrap';

const EditOrder = () => {
  const [order, setOrder] = useState({ status: '', order_items: [], user: {} });  // Added user object
  const [loading, setLoading] = useState(true);
  const { tokens } = useAuth();
  const { id } = useParams();
  const [statusChoices, setStatusChoices] = useState([]);
  const [notes, setNotes] = useState('');


  useEffect(() => {
    if (tokens.access) {
      const config = {
        headers: { Authorization: `Bearer ${tokens.access}` }
      };

      axios.get(`/api/orders/${id}/`, config)
      .then(response => {
        setOrder(response.data);
        setNotes(response.data.notes || ''); // Initialize notes state
        setLoading(false);
      })
      .catch(error => {
        console.error('There was an error fetching the order:', error);
        setLoading(false);
      });
    } else {
      console.log('Token not available');
    }
  }, [id, tokens]);

  useEffect(() => {
    // Fetch order statuses from the backend
    axios.get('/api/order-status-choices/')
      .then((response) => {
        // Convert the array of arrays into an array of objects with value and label properties
        const formattedStatusChoices = response.data.map((status) => ({
          value: status[0],
          label: status[1]
        }));
        setStatusChoices(formattedStatusChoices);
      })
      .catch((error) => {
        console.error('Error fetching order status choices:', error);
      });
  }, []);

  const handleStatusChange = (event) => {
    const { value } = event.target;
    setOrder({
      ...order,
      status: value
    });
  };

  const handleItemQuantityChange = (itemId, event) => {
    const newQuantity = parseInt(event.target.value, 10);  // Convert the input value to an integer
  
    // Update the order_items array with the new quantity for the specific item
    const updatedOrderItems = order.order_items.map((orderItem) => {
      if (orderItem.item.id === itemId) {
        return { ...orderItem, quantity: newQuantity };
      }
      return orderItem;
    });
  
    // Update the order state with the new order_items array
    setOrder({ ...order, order_items: updatedOrderItems });
  };

  const handleRemoveItem = (itemId) => {
    // Logic to remove an item from the order
  };

  const handleAddItem = () => {
    const styleNumberInput = document.getElementById('styleNumberInput');
    const itemStyleNumber = styleNumberInput.value; // Get the current value of the input field
    console.log(`Retrieved style number: ${itemStyleNumber}`); // Should log the typed style number

    // Check if the tokens are available
    if (tokens.access && itemStyleNumber) {
      // Configuration for the authorization header
      const config = {
        headers: { Authorization: `Bearer ${tokens.access}` }
      };
  
      // First, find the item by its style number
      axios.get(`/api/items/?style_number=${itemStyleNumber.toString()}`, config)
      .then(response => {
        console.log('Response for style number search:', response.data); // This will show what the backend returned
          // If the item is found, use the item ID to add to the order
          const item = response.data[0];  // Take the first item from the response
          if (item) {
            return axios.post(`/api/orders/${id}/add-item/`, { item_id: item.id }, config);
          } else {
            throw new Error('Item not found');
          }
        })
        .then(response => {
          console.log('Updated order data:', response.data);
          setOrder(response.data);
        
        })
        .catch(error => {
          console.error('There was an error adding the item to the order:', error);
        });
    } else {
      console.log('Token not available or style number is empty');
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (tokens.access) {
      const updatedOrder = {
        ...order,
        notes: notes,  // Include the updated notes
      };
  
      axios.put(`/api/orders/${id}/`, updatedOrder, {
        headers: { Authorization: `Bearer ${tokens.access}` }
      })
        .then(response => {
          console.log('Order updated:', response.data);
        })
        .catch(error => {
          console.error('There was an error updating the order:', error);
        });
    } else {
      console.log('Token not available');
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
<div>
<h2>Edit Order</h2>
<h4>Placed by: {order.user.username}</h4>  {/* Displaying the username */}
<Form onSubmit={handleSubmit}>
<Form.Group>
          <Form.Label>Status</Form.Label>
          <Form.Control as="select" name="status" value={order.status} onChange={handleStatusChange}>
            {statusChoices.map((status) => (
              <option key={status.value} value={status.value}>
                {status.label}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        {!loading && order && order.order_items.map((orderItem) => (
          <Card key={orderItem.item.id} className="mb-2">
            <Card.Body>
              <Card.Title>{orderItem.item.name}</Card.Title>
              <p>Style Number: {orderItem.item.style_number}</p>
              <InputGroup className="mb-3">
                <FormControl
                  type="number"
                  placeholder={`Quantity: ${orderItem.quantity}`} // Show current quantity as placeholder
                  value={orderItem.quantity} // Bind this to a state if you're managing quantities
                  onChange={(e) => handleItemQuantityChange(orderItem.item.id, e)}
                />
                <InputGroup>
                  <Button variant="outline-secondary" onClick={() => handleRemoveItem(orderItem.item.id)}>
                    Remove
                  </Button>
                </InputGroup>
              </InputGroup>
            </Card.Body>
          </Card>
        ))}
{/* Add search bar and logic for adding items here */}
<h2>Add Item:</h2>
<InputGroup className="mb-3">
          <FormControl
            id="styleNumberInput"
            placeholder="Style Number"
            aria-label="Style Number"
            aria-describedby="basic-addon2"
          />
          <InputGroup>
          <Button variant="outline-secondary" type="button" onClick={handleAddItem} >
            Add Item
          </Button>



          </InputGroup>
        </InputGroup>
        <textarea 
        className='col-md-12'
        value={notes} 
        onChange={(e) => setNotes(e.target.value)}
        placeholder="Add notes here..."
        />
        <Button variant="primary" type="submit" onClick={handleSubmit}>
          Update Order
        </Button>

        
                {/* Add search bar and logic for adding items here */}


     </Form>
    </div>
  );
};

export default EditOrder;
