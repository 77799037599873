import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './components/AuthContext';
import Sidebar from './components/SideBar';
import Login from './components/Login';
import DashboardHome from './components/DashboardHome';
import OrderList from './components/OrderList';
import EditOrder from './components/EditOrder';
import ItemList from './components/ItemList';
import EditItem from './components/EditItem';
import 'bootstrap/dist/css/bootstrap.min.css';
import PrivateRoute from './PrivateRoute'; // Import the standalone PrivateRoute component
import Unauthorized from './components/Unauthorized';
import EmailTool from './components/EmailTool';




function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="App container-fluid">
          <div className="row min-vh-100 flex-column flex-md-row">
            <aside className="col-12 col-md-3 col-xl-2 p-0 bg-dark">
              <Sidebar />
            </aside>
            <main className="col">
              <div className="container">
                <Routes>
                  <Route path="/login" element={<Login />} />
                  <Route path="/" element={<Login />} />
                  <Route path="/dashboard" element={
                    <PrivateRoute allowedRoles={['full_control', 'manage_orders', 'edit_items']}>
                      <DashboardHome />
                    </PrivateRoute>
                  } />
                  <Route path="/orders" element={
                      <PrivateRoute allowedRoles={['full_control', 'manage_orders', 'edit_items']}>
                        <OrderList />
                      </PrivateRoute>
                  } />
                  <Route path="/orders/:id/edit" element={
                    <PrivateRoute><EditOrder /></PrivateRoute>
                  } />
                  <Route path="/items" element={
                    <PrivateRoute><ItemList /></PrivateRoute>
                  } />
                  <Route path="/items/:id/edit" element={
                    <PrivateRoute><EditItem /></PrivateRoute>
                  } />
                  <Route path="/unauthorized" element={<Unauthorized />} />
                  <Route path="/emailtool" element={<EmailTool />} />
                  {/* Add more private routes here as needed */}
                </Routes>
              </div>
            </main>
          </div>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
