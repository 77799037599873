// Sidebar.js
import React from 'react';
import { Nav } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';

const Sidebar = () => {
  const location = useLocation();

  // Define your active style
  const activeStyle = {
    color: 'white',
    backgroundColor: '#004080', // Deep blue color
  };

  const checkActive = (path) => {
    return location.pathname === path ? activeStyle : {};
  };

  return (
    <Nav defaultActiveKey="/" className="flex-column bg-dark" style={{ minHeight: '100vh' }}>
      <Nav.Item>
        <Nav.Link as={Link} to="/dashboard" style={checkActive('/')}>Dashboard</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link as={Link} to="/orders" style={checkActive('/orders')}>Orders</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link as={Link} to="/items" style={checkActive('/items')}>Items</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link as={Link} to="/stats" style={checkActive('/stats')}>Stats</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link as={Link} to="/emailtool" style={checkActive('/emailtool')}>Email Tool</Nav.Link>
      </Nav.Item>
      {/* Add more links here as needed */}
    </Nav>
  );
};

export default Sidebar;
