import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from './AuthContext';
import { useParams } from 'react-router-dom';

const EditItem = () => {
  const [item, setItem] = useState({
    name: '',
    description: '',
    price: '',
    image_url: null,
    stock_quantity: '',
    sizes: '',
    featured: false,
    featured_image: null,
    featured_link: '',
    style_number: '',
    categoryfilter: '',
    brand: '',
    gender: 'U', // default value as per your model
  });
  const [loading, setLoading] = useState(true);
  const { tokens } = useAuth();
  const { id } = useParams();

  useEffect(() => {
    if (tokens.access) {
      const config = {
        headers: { Authorization: `Bearer ${tokens.access}` }
      };

      axios.get(`/api/items/${id}`, config)
        .then(response => {
          setItem(response.data);
          setLoading(false);
        })
        .catch(error => {
          console.error('There was an error fetching the item:', error);
          setLoading(false);
        });
    } else {
      console.log('Token not available');
      // Optionally redirect to login or handle the missing token as needed
    }
  }, [id, tokens]); // Use id from useParams

  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    if (type === 'file') {
      setItem({ ...item, [name]: files[0] });
    } else if (type === 'checkbox') {
      setItem({ ...item, [name]: checked });
    } else {
      setItem({ ...item, [name]: value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (tokens.access) {
      const formData = new FormData();
      Object.keys(item).forEach(key => {
        if (key === 'image_url' || key === 'featured_image') {
          if (item[key] && item[key] instanceof File) {
            formData.append(key, item[key]);
          }
        } else if (key === 'categoryfilter' || key === 'brand') {
          formData.append(key, item[key] || ''); // Empty string for null value
        } else if (key === 'featured') {
          formData.append(key, item[key] ? 'true' : 'false');
        } else if (key === 'featured_link') {
          // Append featured_link only if it is a non-empty string
          if (item[key] && item[key].trim() !== '') {
            formData.append(key, item[key].trim());
          }
        } else {
          formData.append(key, item[key]);
        }
      });

      const config = {
        headers: { 
          Authorization: `Bearer ${tokens.access}`,
          'Content-Type': 'multipart/form-data' // This is needed for file upload
        }
      };
  
      axios.put(`/api/items/${item.id}/`, formData, config)
        .then(response => {
          console.log('Item updated:', response.data);
          // Handle successful update
        })
        .catch(error => {
          console.error('There was an error updating the item:', error.response);
          // Handle the error
        });
    } else {
      console.log('Token not available');
      // Handle the missing token
    }
  };

  // Add more form fields as necessary
  return (
    <div className="container mt-5">
    <h2>Edit Item</h2>
    <div className='col-md-8'>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <form onSubmit={handleSubmit}>
          {/* Repeat this pattern for each field you want to edit */}
          <div className="mb-3">
          <label className="form-label" htmlFor="name">
            Name:
            <input
            type="text"
            className="form-control"
            id="name"
            name="name"
            value={item.name}
            onChange={handleChange}
          />
          </label>
          </div>
          <div className="mb-3">
          <label className="form-label" htmlFor="description">
            Description:
            <input 
            type="text" 
            className='form-control'
            id="description"
            name="description" 
            value={item.description || ''} 
            onChange={handleChange} 
            />
          </label>
          </div>
          <div className="mb-3">
          <label className="form-label" htmlFor="Price">
            Price:
            <input
            type="number"
            className='form-control'
            id="price"
            name="price" 
            value={item.price || ''} 
            onChange={handleChange} 
            />
          </label>
          </div>
          <div className="mb-3">
          <label className="form-label" htmlFor='Image'>
            Image:
            <input 
            type="file"
            className='form-control'
            id="image_url" 
            name="image_url" 
            onChange={handleChange} 
            />
          </label>
          </div>
          <div className="mb-3">
          <label className='form-label' htmlFor='Stock Quantity'>
            Stock Quantity:
            <input 
            type="number" 
            className='form-control'
            id="stock_quantity"
            name="stock_quantity" 
            value={item.stock_quantity || ''} 
            onChange={handleChange} 
            />
          </label>
          </div>
          <div className="mb-3">
          <label className='form-label' htmlFor='sizes'>
            Sizes:
            <input 
            type="text"
            className='form-control'
            id="sizes" 
            name="sizes" 
            value={item.sizes || ''} 
            onChange={handleChange} 
            />
          </label>
          </div>
          <div className="mb-3">
          <label className='form-label' htmlFor='Featured'>
            Featured:
            <input 
            type="checkbox" 
            className=''
            id="featured"
            name="featured" 
            checked={item.featured} 
            onChange={handleChange} 
            />
          </label>
          </div>
          <div className="mb-3">
          <label className='form-label' htmlFor='FeaturedImage'>
            Featured Image:
            <input
             type="file" 
             className='form-control'
             id="featured_image"
             name="featured_image" 
             onChange={handleChange} 
             />
          </label>
          </div>
          <div className="mb-3">
          <label className='form-label' htmlFor='StyleNumber'>
            Style Number:
            <input 
            type="text" 
            className='form-control'
            id="style_number"
            name="style_number" 
            value={item.style_number || ''} 
            onChange={handleChange} 
            />
          </label>
          </div>
          <div className="mb-3">
          <label className='form-label' htmlFor='CategoryFilter'>
            Category Filter:
            <input
            type="text" 
            className='form-control'
            id="categoryfilter"
            name="categoryfilter" 
            value={item.categoryfilter || ''} 
            onChange={handleChange} 
            />
          </label>
          </div>
          <div className="mb-3">
          <label className='form-label' htmlFor='Brand'>
            Brand:
            <input 
            type="text" 
            className='form-control'
            id="brand"
            name="brand" 
            value={item.brand || ''} 
            onChange={handleChange} 
            />
          </label>
          </div>
          {/* ... */}
          <div className="mb-3">
          <label className='form-label' htmlFor='FeaturedLink'>
            Featured Link:
            <input 
            type="text" 
            className='form-control'
            id="featured_link"
            name="featured_link" 
            value={item.featured_link || ''} 
            onChange={handleChange} 
            />
          </label>
          </div>
          {/* Include fields for categoryfilter, brand, and gender selection, etc. */}
          <button type="submit">Update Item</button>
        </form>
      )}
      </div>
    </div>
  );
};

export default EditItem;
