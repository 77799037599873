import React, { useState, useRef } from 'react';

function EmailTemplateForm() {
  const [emailSubject, setEmailSubject] = useState('');
  const [emailBody, setEmailBody] = useState('');
  const [file, setFile] = useState(null);
  const emailBodyRef = useRef(null);

  const handlePlaceholderInsert = (placeholder) => {
    const currentText = emailBody;
    const textarea = emailBodyRef.current;
    const startPos = textarea.selectionStart;
    const endPos = textarea.selectionEnd;
    const beforeText = currentText.substring(0, startPos);
    const afterText = currentText.substring(endPos, currentText.length);
    setEmailBody(beforeText + placeholder + afterText);
    setTimeout(() => {
      textarea.selectionStart = textarea.selectionEnd = startPos + placeholder.length;
      textarea.focus();
    }, 0);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file) {
      alert('Please select a file first.');
      return;
    }
    const formData = new FormData();
    formData.append('file', file);
    formData.append('subject', emailSubject);
    formData.append('body', emailBody);
    const response = await fetch('https://api.orderfr.com/api/send-emails/', {
      method: 'POST',
      body: formData,
    });
    // eslint-disable-next-line
    const result = await response.json();
    alert('Emails sent successfully!');
  };

  return (
    <div className="container mt-5">
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="emailSubject" className="form-label">Email Subject</label>
          <input
            type="text"
            className="form-control"
            id="emailSubject"
            value={emailSubject}
            onChange={(e) => setEmailSubject(e.target.value)}
            placeholder="Enter Email Subject"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="emailBody" className="form-label">Email Body</label>
          <textarea
            className="form-control"
            id="emailBody"
            ref={emailBodyRef}
            value={emailBody}
            onChange={(e) => setEmailBody(e.target.value)}
            rows="6"
            placeholder="Write your email template here."
          />
        </div>
        <div className="mb-3 d-grid gap-2 d-md-flex justify-content-md-start">
          <button type="button" className="btn btn-outline-primary me-md-2" onClick={() => handlePlaceholderInsert('{{username}}')}>
            Insert Username
          </button>
          <button type="button" className="btn btn-outline-secondary" onClick={() => handlePlaceholderInsert('{{password}}')}>
            Insert Password
          </button>
        </div>
        <div className="mb-3">
          <input
            type="file"
            className="form-control"
            onChange={(e) => setFile(e.target.files[0])}
          />
        </div>
        <button type="submit" className="btn btn-primary">Send Emails</button>
      </form>
    </div>
  );
}

export default EmailTemplateForm;
