import React, { useState, useEffect } from 'react';  // Import useContext
import axios from 'axios';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { useAuth } from './AuthContext';  // Correct import for useAuth
import { useNavigate } from 'react-router-dom';

const DashboardHome = () => {
  const [totalOrders, setTotalOrders] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const { tokens, logout } = useAuth(); // Destructure logout from the context

  useEffect(() => {
    // Define the config inside the useEffect to avoid re-declaration issues
    const config = {
      headers: { Authorization: `Bearer ${tokens.access}` }
    };
  
    if (tokens.access) {
      // Fetch total number of orders
      axios.get('/api/all-orders/', config)
        .then(response => {
          setTotalOrders(response.data.length);
        })
        .catch(error => {
          console.error('Error fetching total orders:', error);
        });

      // Fetch total number of items
      axios.get('/api/items/', config)
        .then(response => {
          setTotalItems(response.data.length);
        })
        .catch(error => {
          console.error('Error fetching total items:', error);
        });
    } else {
      console.log('Token not available');
    }
  }, [tokens]);
  const navigate = useNavigate(); // Hook for navigation

  const handleLogout = () => {
    logout();
    navigate('/login'); // This will redirect to the login route
  };

  return (
    <Container fluid>
      <Row className="mt-4">
        <Col>
          <h1>Admin Dashboard</h1>
          {/* Logout Button */}
          <Button onClick={handleLogout} variant="secondary">Logout</Button>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <h1>Admin Dashboard</h1>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md={4}>
          <Card>
            <Card.Body>
              <Card.Title>Total Orders</Card.Title>
              <Card.Text>
                {totalOrders}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card>
            <Card.Body>
              <Card.Title>Total Items</Card.Title>
              <Card.Text>
                {totalItems}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card>
            <Card.Body>
              <Card.Title>Other Stats</Card.Title>
              <Card.Text>
                {/* Replace with dynamic data */}
                Info
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default DashboardHome;
