import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useAuth } from './AuthContext'; // Import useAuth

const ItemList = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const { tokens } = useAuth(); // Destructure tokens from the context

  useEffect(() => {
    if (tokens.access) {
      // Set the authorization header using the access token
      const config = {
        headers: { Authorization: `Bearer ${tokens.access}` }
      };

      // Fetch the items from the API with the authorization header
      axios.get('/api/items/', config)
        .then(response => {
          setItems(response.data);
          setLoading(false);
        })
        .catch(error => {
          console.error('There was an error fetching the items:', error);
          setLoading(false);
        });
    } else {
      console.log('Token not available');
      // Optionally redirect to login or handle the missing token as needed
    }
  }, [tokens]); // Add tokens to the dependency array

  const handleDelete = (id) => {
    if (tokens.access) {
      // Set the authorization header using the access token
      const config = {
        headers: { Authorization: `Bearer ${tokens.access}` }
      };

      // Delete the item using API with the authorization header and update the items list
      axios.delete(`/api/items/${id}/`, config)
        .then(() => {
          setItems(items.filter(item => item.id !== id));
        })
        .catch(error => {
          console.error('There was an error deleting the item:', error);
        });
    } else {
      console.log('Token not available');
      // Optionally handle the missing token
    }
  };

  return (
    <div>
      <h2 className="mb-4">Item List</h2>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <table className="table table-striped table-hover">
          <thead className="table-dark">
            <tr>
              <th>Name</th>
              <th>Style Number</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {items.map(item => (
              <tr key={item.id}>
                <td>{item.name}</td>
                <td>{item.style_number}</td>
                <td>
                  <Link to={`/items/${item.id}/edit`} className="btn btn-primary btn-sm me-2">Edit</Link>
                  <button onClick={() => handleDelete(item.id)} className="btn btn-danger btn-sm">Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default ItemList;
