// PrivateRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './components/AuthContext';

const PrivateRoute = ({ children }) => {
  const { tokens } = useAuth();
  return tokens.access ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
