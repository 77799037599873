import React, { createContext, useState, useContext, useEffect } from 'react';

const AuthContext = createContext();

export const useAuth = () => {
  const context = useContext(AuthContext);
  useEffect(() => {
    console.log('Auth tokens changed:', context.tokens);
  }, [context.tokens]);
  return context;
};

export const AuthProvider = ({ children }) => {
  const [tokens, setTokens] = useState({
    access: localStorage.getItem('access_token'),
    refresh: localStorage.getItem('refresh_token'),
  });

  const [userRole, setUserRole] = useState(null); // Add state for user role

  useEffect(() => {
    // Set the tokens in localStorage whenever they change
    localStorage.setItem('access_token', tokens.access);
    localStorage.setItem('refresh_token', tokens.refresh);
  }, [tokens]);

  const login = (access, refresh) => {
    console.log('Logging in, setting tokens:', access, refresh);
    setTokens({ access, refresh });
  };

  const logout = () => {
    console.log('Logging out, clearing tokens');
    setTokens({ access: null, refresh: null });
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
  };

  const authContextValue = { tokens, login, logout };

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};
